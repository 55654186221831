#section_title{
    text-align: left;
    font-weight: bolder;
    font-size: 170%;
}
.text_area{
    height: 100%;
}
#content_text{
    text-align: left;
    padding-top: 10%;
}
