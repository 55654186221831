.DocumentationPage{
    font-family: 'DM Sans', sans-serif;
}

#documentation_page_header{
    font-family: 'Poppins', sans-serif;
    color: #55225D;
    padding-top: 2rem;
    font-weight: bold;
    font-size: 2.5rem;
}
.sub_header{
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    padding-top: 5rem;
}
.code_snippet{
    background-color: #ECE3EE;
    padding: 0.2rem;
}
.table_container{
    width: 100%;
}

th{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    background-color: #55225D;
    color: white;
    padding: 0.5rem;
}
td{
    padding: 0.5rem;
}
tr:nth-of-type(odd) {
    background-color: #ffffff;
}
tr:nth-of-type(even) {
    background-color: #ECE3EE;
}
.my_table{
    width: 100%;
}

@media only screen and (max-width: 650px) {
    #documentation_page_header{
        font-size: 2rem;
    }
}