.MiniCard{
    background-color: #55225D;
    width: 100%;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 25px;
}
.mini_card_icons{
    width: 40%;
}
.mini_card_text{
    font-size: 1rem;
    color: white;
    padding-top: 3rem;
} 

@media only screen and (max-width: 900px){
    .MiniCard{
        display: none;
    }
}