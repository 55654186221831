.box-shadow {
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072);
  }

.RootPage{
  font-family: 'DM Sans', sans-serif;
}

.notice_text{
  background-color: bisque;
  padding: 1rem;
  text-align: center;
}