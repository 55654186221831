.ButtonPrimary{
    width: 10rem;
    height: 4rem;
    border-radius: 45px 0 0 45px;
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
}


.ButtonSecondary{
    width: 10rem;
    height: 2.5rem;
    border-radius: 45px 0 0 45px;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    background-color: white;
}

.btn_end_right{
    border-radius: 45px 0 0 45px;
    background-color: #55225d;
    border: 2.5px solid #55225d;
    color: white;
    font-size: 1rem;
}

.btn_end_left{
    border-radius: 0 45px 45px 0;
    border: 2.5px solid #55225d;
    color: #55225d;
    background-color: white;
    font-size: 1rem;
}

.set_inline{
    display: inline;
}


.btn_no_end{
    border-radius: 45px;
    background-color: #55225d;
    border: 2.5px solid #55225d;
    color: white;
    font-size: 1rem;
}

.btn_no_end_secondary{
    border-radius: 45px;
    border: 2.5px solid #55225d;
    color: #212121;
    font-size: 1rem;
}

.width_expand_full{
    width: 100%;
}

.width_expand_half{
    width: 50%;
}



@media only screen and (max-width: 650px) {
    .btn_end_right{
        width: 9rem;
    }
    .btn_end_left{
        width: 9rem;
    }
    .btn_no_end{
        width: 9rem;
    }
}