.WeatherCard{
    text-align: center;
    background-color: #55225d;
    border-radius: 20px;
    color: white;
    padding: 2rem 1rem;
    font-size: 0.7rem;
}
.card_icon{
    width: 50%;
}
.temperature_val{
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
}