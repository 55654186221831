#login_page_header{
    font-size: 200%;
    margin-top: 5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #55225D;
}
.input_placeholder{
    width: 7rem;
}
#login_btn{
    width: 10rem;
    background-color: #55225D;
    color: rgb(227, 227, 227);
}