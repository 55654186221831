.DashboardPage{
    padding-top: 2.5rem;
}

#user_icon{
    width: 4rem
}
#username{
    font-size: 2.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.informations_row{
    padding-top: 7rem;
}
.info_name{
    font-size: 1.5rem;
    font-weight: bold;
}
.info_icons{
    width: 1.5rem;
}
.info_value{
    padding-left: 1rem;
}

.informations_row_two{
    padding-top: 7rem;
}