
#tagline{
    margin-top: -5rem;
    font-family: 'Poppins', sans-serif;
    font-size: 200%;
    font-weight: bolder;
    color: #55225D;
    
}
#tagline_vision{
    padding-top: 1rem;
    padding-bottom: 5%;
    font-family: 'DM Sans', sans-serif;
    font-weight: medium;
}

.TwoColSection{
    padding-top: 5%;
    padding-bottom: 5%;
}
.section_one{
    height: 100vh;
    padding-top: 5%;
    text-align: center;
}

.section_three{
    padding-top: 5%;
}


#sticker_elem_one{
    position: absolute;
    left: 0;
    width: 16rem;
    top: 10rem;
    z-index: -1;
}

#sticker_elem_two{
    position: absolute;
    right: 0;
    top: 18rem;
    width: 9rem;
    z-index: -1;
}

#sticker_elem_three{
    position: absolute;
    width: 12rem;
    top: 28rem;
    left: 5rem;
    z-index: -1;
}

#sticker_elem_four{
    position: absolute;
    width: 12rem;
    top: 28rem;
    right: 5rem;
    z-index: -1;
}


@media only screen and (max-width: 900px) {

}