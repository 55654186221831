.Footer{
    background-color: #212121;
    height: 100vh;
    color: rgb(219, 219, 219);
    margin-top: 10%;
}
.footer_links{
    text-decoration: none;
    color: rgb(216, 216, 216);
}

#footer_main_logo{
    width:  100%;
}

#footer_col_one{
    text-align: left;
    padding-top: 10%;
}
#copy_right_text{
    padding-top: 10%;
}

#footer_col_two{
    padding-top: 20%;
}
#footer_col_three{
    padding-top: 20%;
}

@media only screen and (max-width: 900px) {
    #footer_col_two{
        padding-top: 12%;
    }
    #footer_col_three{
        padding-top: 12%;
    }
  }

@media only screen and (max-height: 520px){
    #footer_col_two{
        padding-top: 12%;
    }
    #footer_col_three{
        padding-top: 12%;
    }
  }