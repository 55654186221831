#page_header{
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: bold;
}

#main_weather_icon{
    text-align: center;
    width: 70%;
}

#location_name_temp{
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.sub_weather_icon{
    width: 2rem;
}

.cards_row{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.current_forecast_col{
    
    padding: 2rem;
    border-radius: 45px;
    text-align: center;
}

#coordinate_display{
    font-weight: normal;
    font-size: 1rem;
}

.extra_forecasts{
    padding-top: 5rem;
}

.chart_container{
    margin: 5rem 0.2rem;
    font-size: 0.5rem;
}

.graph_title{
    font-size: 0.8rem;
    font-weight: bold;
    color: #55225D;
}

@media only screen and (max-width: 650px){
    .chart_container{
        margin: 2rem 0.2rem;
    }
}