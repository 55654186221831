.PricingCards{
    padding: 3rem;
}

#sub_name{
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
}

#sub_description{
    font-family: 'DM Sans', sans-serif;
}

#package_list{
    font-size: 1rem;
}