.NavigationBar{
    background-color: #55225D;
    padding: 0.5rem;   
}

.main_logo{
    width: 10rem;
}

.nav_link_text{
    color: #ffffff;
    font-weight: bold;
    padding-right: 5%;
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
}

.sample{
    color: red
}

#drop_down_btn{
    background-color: #55225D;
    border: none;
}

#drop_down_menu{
    width: 70vw;
    border-radius: 45;
    border: none;
    color: white;
    background-color: #CDBFD0;
}

.nav_link_text a.active {
    text-decoration: none;
    color: #CDBFD0;
    font-size: 0.80rem;
  }